import actions from './actions';

const initState = {
  profileDetails: [],
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_PROFILE_DETAIL: {
      return {
        ...state,
        profileDetails: action.profileDetails,
      };
    }
    default:
      return state;
  }
}