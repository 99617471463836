import getDevSidebar from '../../customApp/sidebar';
const options = [
  {
    key: 'skincase',
    label: 'sidebar.dashboard',
    leftIcon: 'ion-clipboard',
  },
  { 
    key: 'pendingcase',
    label: 'sidebar.pendingcase',
    leftIcon: 'ion-document'
  },

];
export default options;