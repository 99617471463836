import React, { Component } from "react";
import { connect } from "react-redux";
import Select, {
  SelectOption as Option,
} from '../../components/uielements/select';
import actions from '../../redux/languageSwitcher/actions';


const { changeLanguage } = actions;


class TopbarLanguageSwitcher extends Component {
  constructor(props) {
    super(props);
  }
  

  

  onSelectChange = (key, value) => {
    console.log("value", value)
    const rtlLanguages = ["arabic"]
    this.props.changeLanguage(value);
    const dir = rtlLanguages.includes(value) ? "rtl" : "ltr"
    document.documentElement.dir = dir
  };
  render() {
   
    // const language = this.props.language;
    // console.log("language", language)
    return (
      <Select
        defaultValue={"english"}
        onChange={this.onSelectChange.bind(this, 'status')}
        style={{ width: '170px' }}
      >
        <Option value="english">English</Option>
        <Option value="arabic">Arabic</Option>
      </Select>
    );
  }
}
export default connect(
    state => ({
      ...state.LanguageSwitcher,
    }),
    { changeLanguage }
  )(TopbarLanguageSwitcher);

