import { all, takeEvery, put, call, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import CategoryHelper from '../../helpers/categoryAreaHelper';
import notification from '../../components/notification';
import actions from './actions';
import actionsLoad from '../spinner/actions';

export function* getAllCategories() { 
    /**
     *we can get story records from database from here   
     *This function will call on load container
     */
    yield takeEvery('GET_CATEGORIES_LIST', function*({ }) {
      yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader
      try {        
        const response  = yield call(CategoryHelper.getCategoryList);
        if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
          notification('error', response.message);   
          //yield put(push('/'));
        }else {
          yield put({
            type: actions.LOAD_CATEGORIES,
            categories: response.items, 
          });        
        }
      }
      catch(error){
        notification('error', error.error || error);
      }  
      yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER }); //Close loader
    });
}

export function* addCategoriesSaga(records) {
  yield takeEvery('ADD_CATEGORIES_SAGA', function*({ records }) {

    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader

    //close already open Notifications
    notification('destroy');
    try {
      const response  = yield call(CategoryHelper.addCategory, records);
      // if token expire then this function and redirect to login page
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
        //yield put(push('/'));
      }else {
        notification('success', 'Category added succesfully.'); 
        yield put(push('/dashboard/categories'));
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//Close loader
  });
}

export function* updateCategoriesSaga() {
  yield takeEvery('UPDATE_CATEGORIES_SAGA', function*({ records }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER }); //start loader
    try {
      const response  = yield call(CategoryHelper.updateCategory, records);     
      //close already open Notifications
      notification('destroy'); 
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
        //yield put(push('/'));
      }else {
        notification('success', 'Category updated succesfully.'); 
        yield put(push('/dashboard/categories'));
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//close loader
  });
}

export function* editCategoriesSaga(id) {
  yield takeEvery('EDIT_CATEGORIES_SAGA', function*({ id }) {    
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader
    try {
      const response  = yield call(CategoryHelper.getRecordById, {id: id});
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
        //yield put(push('/'));
      }else{
        yield put({
          type: actions.GET_EDIT_CAT,
          categoriesDetails: response.items, 
        });
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//close loader
  });
}

export function* deleteCategoriesSaga() {
  yield takeEvery('DELETE_CATEGORIES_SAGA', function*({ id }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader
    try {
      const response  = yield call(CategoryHelper.deleteCategory, {id: id});   
      //close already open Notifications
      notification('destroy');  
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);
        //yield put(push('/'));
      }else{
        notification('success', 'Category deleted succesfully.');
        yield put({
          type: actions.GET_CATEGORIES_LIST,
        });
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//close loader
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAllCategories),
    fork(addCategoriesSaga),
    fork(editCategoriesSaga),
    fork(updateCategoriesSaga),
    fork(deleteCategoriesSaga)
  ]);
}