import SuperFetch from './superFetch';

class ProductsHelper {
    /**
     * Below Function for Category List
     */
     getCatList = async  () => {
        return await SuperFetch.get('categories/getCategories').then(response => {
            return response
        });    
    };

    /**
     * Below Function for Product List
     */
    getProductList = async  () => {
        return await SuperFetch.get('products/getList').then(response => {
            console.log(localStorage.getItem('id_token'));
            return response
        });    
    };      

    /**
     * Below Function for Edit Story
     */
    getRecordById = async id => {
        return await SuperFetch.get('products/edit', id).then(response => {
            return response
        })
    }

    /**
     * Below Function for Add Product
     */
    addProduct = async data => {
        return await SuperFetch.post('products/add', data).then(response => {
            return response
        })
    }

    /**
     * Below Function for Update Product
     */
    updateProduct = async data => {
        return await SuperFetch.post('products/update', data).then(response => {
            return response
        })
    }
    
    /**
     * Below Function for delete product
     */
     deleteProduct = async data => {
        return await SuperFetch.post('products/delete', data).then(response => {
            return response
        })
    }
}
export default new ProductsHelper();