import actions from './actions';

const initState = {
  workingDetails: [],
  initialStory: false,

};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.LOAD_WORKING_HOURS: {
      return {
        ...state,
        workingDetails: action.workingDetails,
      };
    }
    
    default:
      return state;
  }
}