import actions from './actions';

const initState = {
  dashboardCounts: [],
  initialCounts: false,
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
          
      case actions.UPDATE_COUNTS: {
        
        return {
          ...state,
          dashboardCounts: action.counts,
          initialCounts: true,
        };
      }

      default:
        return state;
  }
}