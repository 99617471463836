import { all, takeEvery, put, call, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import { setToken, clearToken, getToken, setUserEmailInfo } from '../../helpers/utility';
import AuthHelper from '../../helpers/authHelper';
import notification from '../../components/notification';
import actionsLoad from '../spinner/actions';

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });
    const { history, userInfo } = payload;
    const result = yield call(AuthHelper.login, userInfo);
    
    if (result.token) {      
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: result,
        token: result.token,
        history
      });
    } else {
      notification('error', result.error || result);
      yield put({ type: actions.LOGIN_ERROR });
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });

  });
}

export function* registerRequest(){
  yield takeEvery('REGISTER_REQUEST', function*({ payload }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });
    const { history, userInfo } = payload;
    const result = yield call(AuthHelper.register, userInfo);
    if (result.success == true) {      
      notification('success', "Register successfully");
      history.push('/signin')
    } else {
      notification('error', result.error || result);
     // yield put({ type: actions.LOGIN_ERROR });
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });

  });
}
export function* otpRequest(){
  yield takeEvery('OTP_REQUEST', function*({ payload }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });
    const { history, userInfo } = payload;
    const result = yield call(AuthHelper.otpRequest, userInfo);
    if (result.success == true) {   
      setUserEmailInfo(userInfo.email)   
      notification('success', "OTP verified");
      history.push('/resetpassword')
    } else {
      notification('error', result.error || result);
     // yield put({ type: actions.LOGIN_ERROR });
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });

  });
}

export function* forgotPassword() {
  yield takeEvery('FORGOT_PASSWORD', function*({ payload }) {    
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//Start loader
    const { userInfo } = payload;
    const result = yield call(AuthHelper.forgotpassword, userInfo);//we will receive API response here
    if (result.success === undefined ){
          notification('error', result.error);
    } else if( result.success == true) { 
          notification('success', result.message || result);
          yield put({ type: actions.FORGOT_SUCCESS ,
            isOTP: true}); 
    } else {
          notification('error', result.message || result);
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });
  });
}

// export function* forgotSuccess(){
//   yield takeEvery(actions.FORGOT_SUCCESS, function*({ history }){
//     clearToken();
//     yield put(push('/'));
//   });
// }
export function* resetPassword(){
  yield takeEvery('RESET_PASSWORD', function*({ payload }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });
    const { history, userInfo } = payload;
   
    const result = yield call(AuthHelper.resetPassword, userInfo);
    console.log("result", result)
    if (result.success == true) {      
      notification('success', "Password Reset successfully");
      history.push('/signin')
    } else {
      notification('error', result.message || result);
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });

  });
}
export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*({ payload, history }) {
    yield setToken(payload.token);
    yield put({ type: actions.UPDATE_TYPE, userType: 1, });//update type

    if (history) {
      history.push('/dashboard');
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken();
    yield put(push('/'));
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const { token } = AuthHelper.checkExpirity(getToken());
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: { token },
        token,
        profile: 'Profile'
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(forgotPassword),
    // fork(forgotSuccess),
    fork(otpRequest),
    fork(loginRequest),
    fork(registerRequest),
    fork(loginSuccess),
    fork(resetPassword),
    fork(loginError),
    fork(logout)
  ]);
}