import SuperFetch from './superFetch';

class ProfileHelper {

    /**
     * Below Function for Category List
     */
    changeProfilePassword = async data => {
        return await SuperFetch.post('users/changePassword', data).then(response => {
            return response
        });    
    };   
    getProfileApi = async () => {
        return await SuperFetch.get('users/editProfile').then(response => {
            return response
        })
    }
    updateProfile = async data => {
        return await SuperFetch.post('users/updateProfile', data).then(response => {
            return response
        })
    }
    
}
export default new ProfileHelper();