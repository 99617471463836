import Auth from './auth/reducer';
import App from './app/reducer';
import Mails from './mail/reducer';
import Calendar from './calendar/reducer';
import Box from './box/reducer';
import Notes from './notes/reducer';
import Todos from './todos/reducer';
import Cards from './card/reducer';
import Chat from './chat/reducers';
import DynamicChartComponent from './dynamicEchart/reducer';
import Ecommerce from './ecommerce/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import Invoices from './invoice/reducer';
import SkinSpinner from './spinner/reducer';
import Emailtemplate from './email-template/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import YoutubeSearch from './youtubeSearch/reducers';
import DevReducers from '../customApp/redux/reducers';
import Articles from './articles/reducers';
import Investors from './investors/reducers';
import scrumBoard from './scrumBoard/reducer';
import drawer from './drawer/reducer';
import modal from './modal/reducer';
import Products from './products/reducer';
import ServiceArea from './serviceArea/reducer'
import WorkingHours from './workingHours/reducer'
import Dashboard from './dashboard/reducer';
import Categories from './categories/reducer';
import Profile from './profile/reducer';

export default {
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Mails,
  Calendar,
  Box,
  Notes,
  Todos,
  Cards,
  Chat,
  DynamicChartComponent,
  Ecommerce,
  Invoices,
  Emailtemplate,
  SkinSpinner,
  YoutubeSearch,
  Articles,
  Investors,
  scrumBoard,
  modal,
  drawer,
  Products,
  Dashboard,
  ServiceArea,
  WorkingHours,
  Categories,
  Profile,
  ...DevReducers,
};