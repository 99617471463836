import SuperFetch from './superFetch';

class MemberHelper {
    
    addPlan = async data => {
        return await SuperFetch.post('payment/offlinePayment', data).then(response => {
            return response
        })
    }

    getMotherLang = async () => {
        return await SuperFetch.get('common/get-mother-tongue',{}).then(response => {
            return response
        });    
    };

    exportUsers = async () => {
        return await SuperFetch.get('user/demoExportXlx',{}).then(response => {
            return response
        });    
    };
    
    getPlanList = async () => {
        return await SuperFetch.get('plans/get-plans',{}).then(response => {
            return response
        });    
    };

    getAdminDetails = async () => {
        return await SuperFetch.get('admin/getAdminDetails',{}).then(response => {
            return response
        });    
    };

    getReligion = async () => {
        return await SuperFetch.get('common/get-religions',{}).then(response => {
            return response
        });    
    };

    getEduList = async () => {
        return await SuperFetch.get('common/getEducationList',{}).then(response => {
            return response
        });    
    };

    sendEmailToMembers = async id => {
        return await SuperFetch.post('user/sendEmailToMembers', id).then(response => {
            return response
        });    
    }; 
    
    getOccupationList = async id => {
        return await SuperFetch.get('common/getOccupationList').then(response => {
            return response
        });    
    }; 

    getContactHistory = async id => {
        return await SuperFetch.get('user/myViewedHistory', id).then(response => {
            return response
        });    
    }; 

    getMemberPaymentList = async id => {
        return await SuperFetch.get('user/getMemberPaymentList', id).then(response => {
            return response
        });    
    }; 

    getCasteList = async id => {
        return await SuperFetch.get('common/get-caste', id).then(response => {
            return response
        });    
    }; 

    getShortlistedUsers = async user_id => {
        return await SuperFetch.post('user/getShortlistedUsers', user_id).then(response => {
            return response
        });    
    };      

    getInterestedUsers = async  () => {
        return await SuperFetch.post('user/getInterestedUsers').then(response => {
            return response
        });    
    };      

    getmemberlist = async  () => {
        return await SuperFetch.post('user/getMemberList').then(response => {
            return response
        });    
    };    
    
    getRecordById = async id => {
        return await SuperFetch.get('user/get-profile', id).then(response => {
            return response
        })
    }

    addMember = async data => {
        return await SuperFetch.post('user/register', data).then(response => {
            return response
        })
    }
    
    updateMember = async data => {
        return await SuperFetch.post('user/updateProfile', data).then(response => {
            return response
        })
    }

    updateAdmin = async data => {
        return await SuperFetch.post('admin/updateAdminProfile', data).then(response => {
            return response
        })
    }
    
    deletemember = async data => {
        return await SuperFetch.delete('user/deletemember', data).then(response => {
            return response
        })
    }

    getActiveMembers = async  () => {
        return await SuperFetch.post('user/getMemberList').then(response => {
            return response
        });    
    };    

}
export default new MemberHelper();