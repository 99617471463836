import jwtDecode from 'jwt-decode';
import SuperFetch from './superFetch';

class AuthHelper {
  login = async userInfo => {
    if (!userInfo.username || !userInfo.password) {
      return { error: 'Please enter username and password' };
    }
    return await SuperFetch.post('users/login', {...userInfo, email: userInfo.username}).then(response => {
      return this.checkExpirity(response.token);
    });
  };
  register = async userInfo => {

    if (!userInfo.fname  || !userInfo.lname  || !userInfo.email || !userInfo.password || !userInfo.cpassword) {
      return { error: 'All field required' };
    }
    if(userInfo.password != userInfo.cpassword){
      return { error: 'Password not match' };
    }
    return await SuperFetch.post('users/signup', {...userInfo}).then(response => {
      return response;
    });
  };
  getUserType = async userInfo => {
    return await SuperFetch.post('getUserType',userInfo).then(response => {
      return response;
    });
  };
  otpRequest = async userInfo => {
    if (!userInfo.otp) {
      return { error: 'Please enter Otp' };
    }
    if (userInfo.otp.length !== 6) {
      return { error: 'Please 6 digit Otp number' };
    }
    return await SuperFetch.post('users/verifyOtp', userInfo).then(response => {
      return response;
    });
  };
  resetPassword = async userInfo => {
    if (!userInfo.new_password  || !userInfo.cpassword) {
      return { error: 'All field required' };
    }
    if(userInfo.new_password != userInfo.cpassword){
      return { error: 'Password not match' };
    }
    return await SuperFetch.post('users/newPassword', userInfo).then(response => {
      return response;
    });
  };
  forgotpassword = async userInfo => {
    if (!userInfo.email) {
      return { error: 'Please enter EMAIL-ID to reset password' };
    }
    return await SuperFetch.post('users/forgotPassword', userInfo).then(response => {
      return response;
    });
  };
  async checkDemoPage(token) {
    if (this.checkExpirity(token).error) {
      return { error: 'Token expired' };
    }
    return await SuperFetch.get('secret/test', { token })
      .then(response => ({
        status: '200',
        message: 'Success',
      }))
      .catch(error => ({ error: JSON.stringify(error) }));
  }
  checkExpirity = token => {
    if (!token) {
      return {
        error: 'Username and Password not matched',
      };
    }
    try {
      const profile = jwtDecode(token);

      const expiredAt = profile.expiredAt || profile.exp * 1000;

      if (expiredAt > new Date().getTime()) {
        return {
          ...profile,
          token,
          expiredAt: new Date(expiredAt),
        };
      } else {
        return { error: 'Token expired' };
      }
    } catch (e) {
      console.log(e);

      return { error: 'Server Error' };
    }
  };
}
export default new AuthHelper();