const options = [
  {
    key: '',
    label: 'sidebar.dashboard',
    leftIcon: 'ion-clipboard',
  },
  {
    key: 'categories',
    label: 'sidebar.category',
    leftIcon: 'ion-images'
  },
  {
    key: 'products',
    label: 'sidebar.product',
    leftIcon: 'ion-images'
  },
  {
    key: 'service-areas',
    label: 'sidebar.serviceArea',
    leftIcon: 'ion-images'
  },
  {
    key: 'working-hours',
    label: 'sidebar.workingHours',
    leftIcon: 'ion-images'
  },
  /* {
    key: 'settings',
    label: 'sidebar.settings',
    leftIcon: 'ion-person-stalker',
    children: [ 
      {
        key: 'contact-us',
        label: 'sidebar.contactus',
      },
      {
        key: 'branches',
        label: 'sidebar.branches',
      },
      {
        key: 'send-email',
        label: 'sidebar.sendEmail',
      }
    ]
  }, */
];
export default options;