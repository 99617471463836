import { all, takeEvery, put, call, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import ProductsHelper from '../../helpers/productHelper';
import notification from '../../components/notification';
import actions from './actions';
import moment from 'moment';
import actionsLoad from '../spinner/actions';
import MemberHelper from '../../helpers/memberHelper';


export function* getAllCategory() { 
  /**
   *we can get category records from database from here   
   *This function will call on load container
   */
  yield takeEvery('GET_CAT_LIST', function*({ }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader
    try {        
      const response  = yield call(ProductsHelper.getCatList);
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
        //yield put(push('/'));
      }else {
        yield put({
          type: actions.LOAD_CATEGORY,
          categoryList: response.items, 
        });        
      }
    }
    catch(error){
      notification('error', error.error || error);
    }  
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER }); //Close loader
  });
}

export function* getSingleProduct() {
  yield takeEvery('GET_PRODUCT', function*({ }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader
    try {        
      const response  = yield call(ProductsHelper.getRecordById);
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
        //yield put(push('/'));
      }else {
        yield put({
          type: actions.EDIT_PRODUCT_INFO,
          editProductInfo: response.items, 
        });        
      }
    }
    catch(error){
      notification('error', error.error || error);
    }  
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER }); //Close loader
  });
}

export function* getAllProducts() { 
    /**
     *we can get story records from database from here   
     *This function will call on load container
     */
    yield takeEvery('GET_PRODUCT_LIST', function*({ }) {
      yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader
      try {        
        const response  = yield call(ProductsHelper.getProductList);
        if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
          notification('error', response.message);   
          //yield put(push('/'));
        }else {
          yield put({
            type: actions.LOAD_PRODUCTS,
            products: response.items, 
          });        
        }
      }
      catch(error){
        notification('error', error.error || error);
      }  
      yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER }); //Close loader
    });
}

export function* addproductSaga(records) {
  yield takeEvery('ADD_PRODUCT_SAGA', function*({ records }) {

    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader

    //close already open Notifications
    notification('destroy');
    try {
      const response  = yield call(ProductsHelper.addProduct, records);
      // if token expire then this function and redirect to login page
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
        //yield put(push('/'));
      }else {
        notification('success', 'Product added succesfully.'); 
        yield put(push('/dashboard/products'));
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//Close loader
  });
}

export function* updateProductSaga() {
  yield takeEvery('UPDATE_PRODUCT_SAGA', function*({ records }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER }); //start loader
    try {
      const response  = yield call(ProductsHelper.updateProduct, records);     
      //close already open Notifications
      notification('destroy'); 
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
        //yield put(push('/'));
      }else {
        notification('success', 'Product updated succesfully.'); 
        yield put(push('/dashboard/products'));
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//close loader
  });
}

export function* editProductSaga(id) {
  yield takeEvery('EDIT_PRODUCT_SAGA', function*({ id }) {    
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader
    try {
      const response  = yield call(ProductsHelper.getRecordById, {id: id});
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
        //yield put(push('/'));
      }else{
        yield put({
          type: actions.GET_EDIT,
          productDetails: response.items, 
        });
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//close loader
  });
}

export function* deleteProductSaga() {
  yield takeEvery('DELETE_PRODUCT_SAGA', function*({ id }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader
    try {
      const response  = yield call(ProductsHelper.deleteProduct, {id: id});   
      //close already open Notifications
      notification('destroy');  
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);
        //yield put(push('/'));
      }else{
        notification('success', 'Product deleted succesfully.'); 
        yield put({
          type: actions.GET_PRODUCT_LIST,
        });
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//close loader
  });
}

export default function* rootSaga() {
  
  yield all([
    fork(getAllProducts),
    fork(addproductSaga),
    fork(editProductSaga),
    fork(updateProductSaga),
    fork(deleteProductSaga),
    fork(getSingleProduct), 
    fork(getAllCategory)
  ]);
}