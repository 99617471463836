import SuperFetch from './superFetch';

class WorkingHoursHelper {
    /**
     * Below Function for All product List 
     */
    getWorkingHours = async  () => {
        return await SuperFetch.get('users/getWorkingHours').then(response => {
            return response
        });    
    }
    /**
     * Below Function for updateWorkingHours
     */
    updateWhours = async data => {
        return await SuperFetch.post('users/updateWorkingHours', data).then(response => {
            return response
        })
    }
}
export default new WorkingHoursHelper();