import antdSA from 'antd/lib/locale-provider/en_US';
import appLocaleData from 'react-intl/locale-data/ar';
import saMessages from '../locales/ar_AR.json';

const arLang = {
  messages: {
    ...saMessages
  },
  antd: antdSA,
  locale: 'ar',
  data: appLocaleData
};
export default arLang;
