import SuperFetch from './superFetch';

class CategoryHelper {

    /**
     * Below Function for Category List
     */
    getCategoryList = async  () => {
        return await SuperFetch.get('categories/getCategories').then(response => {
            return response
        });    
    };      

    /**
     * Below Function for Edit categories
     */
    getRecordById = async id => {
        return await SuperFetch.get('categories/edit', id).then(response => {
            return response
        })
    }

    /**
     * Below Function for Add Categories
     */
     addCategory = async data => {
        return await SuperFetch.post('categories/add', data).then(response => {
            return response
        })
    }

    /**
     * Below Function for Update Categories
     */
    updateCategory = async data => {
        return await SuperFetch.post('categories/update', data).then(response => {
            return response
        })
    }
    
    /**
     * Below Function for delete Categories
     */
    deleteCategory = async data => {
        return await SuperFetch.post('categories/delete', data).then(response => {
            return response
        })
    }
}
export default new CategoryHelper();