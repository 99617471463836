import actions from './actions';

const initState = {
  products: [],
  categoryList: [],
  productDetails: [],
  initialStory: false,
  editProductInfo: [],
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_EDIT: {
      return {
        ...state,
        productDetails: action.productDetails,
      };
    }
    
    case actions.EDIT_PRODUCT_INFO: {
      return {
        ...state,
        editProductInfo: action.editProductInfo,
      };
    }

    case actions.LOAD_PRODUCTS: {
      return {
        ...state,
        products: action.products,
        initialStory: true,
      };
    }

    case actions.LOAD_CATEGORY: {
      return {
        ...state,
        categoryList: action.categoryList,
      };
    }

    default:
      return state;
  }
}