import actions from './actions';

const initState = {
  servicearea: [],
  productListSa: [],
  serviceAreaDetails: [],
  initialStory: false,
  editProductInfo: [],
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_EDIT_SA: {
      return {
        ...state,
        serviceAreaDetails: action.serviceAreaDetails,
      };
    }
    
    case actions.EDIT_PRODUCT_INFO: {
      return {
        ...state,
        editProductInfo: action.editProductInfo,
      };
    }

    case actions.LOAD_SERVICEAREA: {
      return {
        ...state,
        servicearea: action.servicearea,
      };
    }

    case actions.LOAD_PRODUCT_SA: {
      return {
        ...state,
        productListSa: action.productListSa,
      };
    }

    default:
      return state;
  }
}