import { jwtConfig } from '../settings';

const customHeader = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: /* 'Bearer ' +  */localStorage.getItem('id_token') || undefined,
});
 // for serializing the object to query string
 const serialize = (obj = {}) => {
  return Object.keys(obj).length
    ? "?" +
        Object.keys(obj)
          .map(
            (k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`
          )
          .join("&")
    : "";
}

const base = (method, url, data = {}) => {
  return fetch(`${jwtConfig.fetchUrl}${url}`, {
    method,
    headers: customHeader(),
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(res => res)
    .catch(error => ({ error: error }));
};

const baseGet = (method, url, data = {}) => {
  return fetch(`${jwtConfig.fetchUrl}${url}${serialize(data)}`, {
    method,
    headers: customHeader()
  })
    .then(response => response.json())
    .then(res => res)
    .catch(error => ({ error: error }));
};

const SuperFetch = {};
['post', 'put', 'delete'].forEach(method => {
  SuperFetch[method] = base.bind(null, method);
});
SuperFetch['get'] = baseGet.bind(null, 'get');
export default SuperFetch;
