const actions = {
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    GET_PROFILE: "GET_PROFILE",
    UPDATE_PROFILE: "UPDATE_PROFILE",
    GET_PROFILE_DETAIL: "GET_PROFILE_DETAIL",
    changePassword: (records) => {
      return (dispatch) => {
        dispatch({
          type: actions.CHANGE_PASSWORD,
          records,
        });
      };
    },
    editProfile: () => ({
      type: actions.GET_PROFILE
    }),
    
    updateProfile: (records) => {
      return (dispatch) => {
        dispatch({
          type: actions.UPDATE_PROFILE,
          records,
        });
      };
    },
  };
  export default actions;