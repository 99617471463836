import SuperFetch from './superFetch';

class ServiceAreaHelper {
    /**
     * Below Function for All product List 
     */
     getAllProducts = async  () => {
        return await SuperFetch.get('products/getList').then(response => {
            return response
        });    
    }; 

    /**
     * Below Function for Service area List
     */
    getServiceAreaList = async  () => {
        return await SuperFetch.get('service-area/getList').then(response => {
            return response
        });    
    };      

    /**
     * Below Function for Edit service-area
     */
    getRecordById = async id => {
        return await SuperFetch.get('service-area/edit', id).then(response => {
            return response
        })
    }

    /**
     * Below Function for Add Service Area
     */
    addServiceArea = async data => {
        return await SuperFetch.post('service-area/add', data).then(response => {
            return response
        })
    }

    /**
     * Below Function for Update Service area
     */
    updateServiceArea = async data => {
        return await SuperFetch.post('service-area/update', data).then(response => {
            return response
        })
    }
    
    /**
     * Below Function for delete service area
     */
    deleteServiceArea = async data => {
        return await SuperFetch.post('service-area/delete', data).then(response => {
            return response
        })
    }
}
export default new ServiceAreaHelper();