import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import invoicesSagas from './invoice/saga';
import emailtemplateSagas from './email-template/saga'
import productsSagas from './products/saga'
import dashboardSagas from './dashboard/saga'
import serviceAreaSags from './serviceArea/saga'
import categoriesSagas from './categories/saga'
import profileSagas from './profile/saga'
import workingHoursSaga from './workingHours/saga'
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    invoicesSagas(),
    emailtemplateSagas(),
    workingHoursSaga(),
    productsSagas(),
    dashboardSagas(),
    serviceAreaSags(),
    categoriesSagas(),
    profileSagas()
  ]);
}