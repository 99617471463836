export default JSON.parse(
  `[{
  "id": 94926,
  "note": "Title: Note created on 4/24/2019 9:30 AM by Skin MD Now",
  "createTime": "2017-02-10T07:42:44.828Z",
  "color": 0
}, {
  "id": 51842,
  "note": "Title: Note created on 4/24/2019 9:30 AM by TechExtensor",
  "createTime": "2017-04-08T03:17:31.999Z",
  "color": 0
}, {
  "id": 28180,
  "note": "Title: Note created on 4/24/2019 9:30 AM by Skin MD Now",
  "createTime": "2017-01-21T05:43:16.889Z",
  "color": 3
}, {
  "id": 29619,
  "note": "Title: Note created on 4/24/2019 9:30 AM by TechExtensor",
  "createTime": "2017-01-08T23:36:05.692Z",
  "color": 4
}, {
  "id": 19600,
  "note": "Title: Note created on 4/24/2019 9:30 AM by Skin MD Now",
  "createTime": "2017-05-11T22:30:14.915Z",
  "color": 4
}]`,
);
