const actions = {
    GET_PRODUCT: 'GET_PRODUCT',
    EDIT_PRODUCT_INFO: 'EDIT_PRODUCT_INFO',
    GET_PRODUCT_LIST: 'GET_PRODUCT_LIST',
    GET_EDIT: 'GET_EDIT',
    LOAD_PRODUCTS: 'LOAD_PRODUCTS',
    ADD_PRODUCT_SAGA: 'ADD_PRODUCT_SAGA',
    EDIT_PRODUCT_SAGA: 'EDIT_PRODUCT_SAGA',
    UPDATE_PRODUCT_SAGA: 'UPDATE_PRODUCT_SAGA',
    DELETE_PRODUCT_SAGA: 'DELETE_PRODUCT_SAGA',
    GET_CAT_LIST: "GET_CAT_LIST",
    LOAD_CATEGORY: "LOAD_CATEGORY",
    getCatList: () => ({type: actions.GET_CAT_LIST}),
    getProduct: () => ({type: actions.GET_PRODUCT}),
    initData: () => ({ type: actions.GET_PRODUCT_LIST }),
    addProduct: records => {
      return (dispatch) => {
        dispatch({
          type: actions.ADD_PRODUCT_SAGA,
          records
        });
      };
    },
    editProduct: id => ({
        type: actions.EDIT_PRODUCT_SAGA,
        id
    }),
    updateProduct: records => {
        return (dispatch) => {
          dispatch({
            type: actions.UPDATE_PRODUCT_SAGA,
            records
          });
      }
    },
    deleteProduct: id => {
        return (dispatch) => {
          dispatch({
            type: actions.DELETE_PRODUCT_SAGA,
            id
          })
        }
    }
  };
export default actions;