const actions = {
  GET_CATEGORIES_LIST: "GET_CATEGORIES_LIST",
  GET_EDIT_CAT: "GET_EDIT_CAT",
  LOAD_CATEGORIES: "LOAD_CATEGORIES",
  ADD_CATEGORIES_SAGA: "ADD_CATEGORIES_SAGA",
  EDIT_CATEGORIES_SAGA: "EDIT_CATEGORIES_SAGA",
  UPDATE_CATEGORIES_SAGA: "UPDATE_CATEGORIES_SAGA",
  DELETE_CATEGORIES_SAGA: "DELETE_CATEGORIES_SAGA",
  initData: () => ({ type: actions.GET_CATEGORIES_LIST }),
  addCategory: (records) => {
    return (dispatch) => {
      dispatch({
        type: actions.ADD_CATEGORIES_SAGA,
        records,
      });
    };
  },
  editCategory: (id) => ({
    type: actions.EDIT_CATEGORIES_SAGA,
    id,
  }),
  updateCategory: (records) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_CATEGORIES_SAGA,
        records,
      });
    };
  },
  deleteCategory: (id) => {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_CATEGORIES_SAGA,
        id,
      });
    };
  },
};
export default actions;