import { all, takeEvery, put, call, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import DashboardHelper from '../../helpers/dashboardHelper';
import notification from '../../components/notification';
import actions from './actions';
import actionsLoad from '../spinner/actions';

export function* getCounts() {
    yield takeEvery('GET_COUNTS', function*({ }) {
      yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });
      try {        
        const response  = yield call(DashboardHelper.getDashboardCounts);
        if((response.status != undefined && response.status == 'Unauthenticated.') || response.success === false){
          notification('error', response.message);   
          yield put(push('/'));
        }else {
          yield put({
            type: actions.UPDATE_COUNTS,
            counts: response.items, 
          });        
        }
      }
      catch(error){
        notification('error', error.error || error);
      }
      yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER }); //Close loader
    });
}

export default function* rootSaga() {
  yield all([
    fork(getCounts)
  ]);
}