import { all, takeEvery, put, call, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import ProfileHelper from '../../helpers/profileHelper';
import notification from '../../components/notification';
import actions from './actions';
import actionsLoad from '../spinner/actions';
import profileHelper from '../../helpers/profileHelper';



export function* changePasswordSaga(records) {
    
  yield takeEvery('CHANGE_PASSWORD', function*({ records }) {

    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });

    notification('destroy');
    try {
        
      const response  = yield call(ProfileHelper.changeProfilePassword, records);
      
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
      
      }else {
        notification('success', 'Password change succesfully.'); 
        yield put(push('/dashboard'));
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//Close loader
  });
}
export function* getProfileSaga() {
  yield takeEvery('GET_PROFILE', function*({  }) {    
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader
    try {
      const response  = yield call(ProfileHelper.getProfileApi);
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
      }else{
        yield put({
          type: actions.GET_PROFILE_DETAIL,
          profileDetails: response.items, 
        });
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//close loader
  });
}

export function* updateProfileSaga() {
  yield takeEvery('UPDATE_PROFILE', function*({ records }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER }); //start loader
    try {
      const response  = yield call(profileHelper.updateProfile, records);     
      notification('destroy'); 
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
      }else {
        notification('success', 'Profile updated succesfully.'); 
        yield put(push('/dashboard/update-profile'));
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//close loader
  });
}


export default function* rootSaga() {
  yield all([
    fork(changePasswordSaga),
    fork(getProfileSaga),
    fork(updateProfileSaga),
    // fork(updateCategoriesSaga),
  ]);
}