import { all, takeEvery, put, call, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import ServiceAreaHelper from '../../helpers/serviceAreaHelper';
import notification from '../../components/notification';
import actions from './actions';
import actionsLoad from '../spinner/actions';


export function* getAllProducts() { 
  /**
   *we can get category records from database from here   
   *This function will call on load container
   */
  yield takeEvery('GET_PRODUCT_SA', function*({ }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader
    try {        
      const response  = yield call(ServiceAreaHelper.getAllProducts);
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
        //yield put(push('/'));
      }else {
        yield put({
          type: actions.LOAD_PRODUCT_SA,
          productListSa: response.items, 
        });        
      }
    }
    catch(error){
      notification('error', error.error || error);
    }  
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER }); //Close loader
  });
}

export function* getSingleProduct() {
  yield takeEvery('GET_PRODUCT', function*({ }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader
    try {        
      const response  = yield call(ServiceAreaHelper.getRecordById);
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
        //yield put(push('/'));
      }else {
        yield put({
          type: actions.EDIT_PRODUCT_INFO,
          editProductInfo: response.items, 
        });        
      }
    }
    catch(error){
      notification('error', error.error || error);
    }  
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER }); //Close loader
  });
}

export function* getAllServiceArea() { 
    /**
     *we can get story records from database from here   
     *This function will call on load container
     */
    yield takeEvery('GET_SERVICEAREA_LIST', function*({ }) {
      yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader
      try {        
        const response  = yield call(ServiceAreaHelper.getServiceAreaList);
        if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
          notification('error', response.message);   
          //yield put(push('/'));
        }else {
          yield put({
            type: actions.LOAD_SERVICEAREA,
            servicearea: response.items, 
          });        
        }
      }
      catch(error){
        notification('error', error.error || error);
      }  
      yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER }); //Close loader
    });
}

export function* addServiceAreaSaga(records) {
  yield takeEvery('ADD_SERVICEAREA_SAGA', function*({ records }) {

    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader

    //close already open Notifications
    notification('destroy');
    try {
      const response  = yield call(ServiceAreaHelper.addServiceArea, records);
      // if token expire then this function and redirect to login page
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
        //yield put(push('/'));
      }else {
        notification('success', 'Service Area added succesfully.'); 
        yield put(push('/dashboard/service-areas'));
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//Close loader
  });
}

export function* updateServiceAreaSaga() {
  yield takeEvery('UPDATE_SERVICEAREA_SAGA', function*({ records }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER }); //start loader
    try {
      const response  = yield call(ServiceAreaHelper.updateServiceArea, records);     
      //close already open Notifications
      notification('destroy'); 
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
        //yield put(push('/'));
      }else {
        notification('success', 'Service Area updated succesfully.'); 
        yield put(push('/dashboard/service-areas'));
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//close loader
  });
}

export function* editServiceAreaSaga(id) {
  yield takeEvery('EDIT_SERVICEAREA_SAGA', function*({ id }) {    
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader
    try {
      const response  = yield call(ServiceAreaHelper.getRecordById, {id: id});
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
        //yield put(push('/'));
      }else{
        yield put({
          type: actions.GET_EDIT_SA,
          serviceAreaDetails: response.items, 
        });
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//close loader
  });
}

export function* deleteServiceAreaSaga() {
  yield takeEvery('DELETE_SERVICEAREA_SAGA', function*({ id }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader
    try {
      const response  = yield call(ServiceAreaHelper.deleteServiceArea, {id: id});   
      //close already open Notifications
      notification('destroy');  
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);
        //yield put(push('/'));
      }else{
        notification('success', 'Service area deleted succesfully.'); 
        yield put({
          type: actions.GET_SERVICEAREA_LIST,
        });
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//close loader
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAllServiceArea),
    fork(getAllProducts),
    fork(addServiceAreaSaga),
    fork(editServiceAreaSaga),
    fork(updateServiceAreaSaga),
    fork(deleteServiceAreaSaga),
    fork(getSingleProduct)
  ]);
}