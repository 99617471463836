const actions = {
  GET_PRODUCT_SA: "GET_PRODUCT_SA",
  LOAD_PRODUCT_SA: "LOAD_PRODUCT_SA",
  GET_SERVICEAREA_LIST: "GET_SERVICEAREA_LIST",
  GET_EDIT_SA: "GET_EDIT_SA",
  LOAD_SERVICEAREA: "LOAD_SERVICEAREA",
  ADD_SERVICEAREA_SAGA: "ADD_SERVICEAREA_SAGA",
  EDIT_SERVICEAREA_SAGA: "EDIT_SERVICEAREA_SAGA",
  UPDATE_SERVICEAREA_SAGA: "UPDATE_SERVICEAREA_SAGA",
  DELETE_SERVICEAREA_SAGA: "DELETE_SERVICEAREA_SAGA",
  getProductSa: () => ({ type: actions.GET_PRODUCT_SA }),
  initData: () => ({ type: actions.GET_SERVICEAREA_LIST }),
  addServiceArea: (records) => {
    return (dispatch) => {
      dispatch({
        type: actions.ADD_SERVICEAREA_SAGA,
        records,
      });
    };
  },
  editServiceArea: (id) => ({
    type: actions.EDIT_SERVICEAREA_SAGA,
    id,
  }),
  updateServiceArea: (records) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_SERVICEAREA_SAGA,
        records,
      });
    };
  },
  deleteServiceArea: (id) => {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_SERVICEAREA_SAGA,
        id,
      });
    };
  },
};
export default actions;