const siteConfig = {
  siteName: 'SPN admin portal',
  siteIcon: 'ion-flash',
  footerText: '©2022 SPN Portal'
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};
const language = 'english';
 
const jwtConfig = {
  fetchUrl: 'https://spnapi.herokuapp.com/', //api host url */
  /* fetchUrl: 'http://localhost:3000/', */ //api host url
  adminUrl: 'https://admin.com',
  secretKey: 'secretKey',
};

export {
  siteConfig,
  themeConfig,
  language,
  jwtConfig
};