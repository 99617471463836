import { all, takeEvery, put, call, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import WorkingHoursHelper from '../../helpers/workingHoursHelper';
import notification from '../../components/notification';
import actions from './actions';
import actionsLoad from '../spinner/actions';

export function* getWorkingHoursSaga() {
  yield takeEvery('GET_WORKING_HOURS', function*({  }) {    
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//start loader
    try {
      const response  = yield call(WorkingHoursHelper.getWorkingHours);
      console.log("response working hours", response)
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
      }else{
        yield put({
          type: actions.LOAD_WORKING_HOURS,
          workingDetails: response.items, 
        });
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//close loader
  });
}

export function* updateWhoursSaga() {
  yield takeEvery('UPDATE_WHOURS_SAGA', function*({ records }) {
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER }); //start loader
    try {
      const response  = yield call(WorkingHoursHelper.updateWhours, records);     
      //close already open Notifications
      notification('destroy'); 
      if((response.status !== undefined && response.status === 'Unauthenticated.') || response.success === false){
        notification('error', response.message);   
        //yield put(push('/'));
      }else {
        notification('success', 'Working Hours updated succesfully.'); 
        //yield put(push('/dashboard'));
      }
    }
    catch(error){
      notification('error', error.error || error);      
    }
    yield put({ type: actionsLoad.SWITCH_ACTIVATION_LOADER });//close loader
  });
}


export default function* rootSaga() {
  yield all([
    fork(getWorkingHoursSaga),
    fork(updateWhoursSaga)
  ]);
}