import actions from './actions';

const initState = {
  categories: [],
  categoriesDetails: []
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_EDIT_CAT: {
      return {
        ...state,
        categoriesDetails: action.categoriesDetails,
      };
    }
    
    case actions.LOAD_CATEGORIES: {
      return {
        ...state,
        categories: action.categories,
      };
    }

    default:
      return state;
  }
}