const actions = {
  GET_WORKING_HOURS: "GET_WORKING_HOURS",
  LOAD_WORKING_HOURS: "LOAD_WORKING_HOURS",
  UPDATE_WHOURS_SAGA: "UPDATE_WHOURS_SAGA",
  getWorkingHours: () => ({ type: actions.GET_WORKING_HOURS }),
  updateWorkingHours: records => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_WHOURS_SAGA,
        records
      });
    }
  },
};
export default actions;