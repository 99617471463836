import asyncComponent from '../helpers/AsyncFunc';

const routes = [  
  {
    path: '',
    component: asyncComponent(() => import('./containers/Dashboard')),
  },
  {
    path: 'products',
    component: asyncComponent(() => import('./containers/Products'))
  },
  {
    path: 'products/add',
    component: asyncComponent(() => import('./containers/Products/add'))
  },
  {
    path: 'products/edit/:id',
    component: asyncComponent(() => import('./containers/Products/view'))
  },
  {
    path: 'service-areas',
    component: asyncComponent(() => import('./containers/ServiceArea'))
  },
  {
    path: 'service-areas/add',
    component: asyncComponent(() => import('./containers/ServiceArea/add'))
  },
  {
    path: 'service-areas/edit/:id',
    component: asyncComponent(() => import('./containers/ServiceArea/view'))
  },
  {
    path: 'categories',
    component: asyncComponent(() => import('./containers/Categories'))
  },
  {
    path: 'categories/add',
    component: asyncComponent(() => import('./containers/Categories/add'))
  },
  {
    path: 'categories/edit/:id',
    component: asyncComponent(() => import('./containers/Categories/view'))
  },
  {
    path: 'working-hours',
    component: asyncComponent(() => import('./containers/WorkingHours'))
  },
  {
    path: 'update-profile',
    component: asyncComponent(() => import('./containers/UpdateProfile/editProfile'))
  },
  {
    path: 'changepassword',
    component: asyncComponent(() => import('./containers/UpdateProfile/changePassword'))
  },
];
export default routes;