const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  OTP_REQUEST: 'OTP_REQUEST',
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  FORGOT_SUCCESS: 'FORGOT_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  FORGOT_SUCCESS: 'FORGOT_SUCCESS',
  UPDATE_TYPE: 'UPDATE_TYPE',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: payload => ({
    type: actions.LOGIN_REQUEST,
    payload
  }),
  register: payload => ({
    type: actions.REGISTER_REQUEST,
    payload
  }),
  forgotpassword: payload => ({
    type: actions.FORGOT_PASSWORD,
    payload
  }),
  otp : payload => ({
    type: actions.OTP_REQUEST,
    payload
  }),
  resetPassword: payload => ({
    type: actions.RESET_PASSWORD,
    payload
  }),
  logout: () => ({
    type: actions.LOGOUT
  })
};
export default actions;
